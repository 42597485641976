import {
  IBuyIns,
  IScoreRangeCounts,
  TCandidateSubmittedPerJob,
  TFQResponse,
  THireabilityDistResponse,
  TStringNumberMap,
} from "@/views/dashboard/Analytics/types";

import axiosInstance from "../axiosInstance";

const base = "/analytics";
const submissions = "/submissions-per-job-bucket";
const submissions_per_job = "/candidate-submitted-per-job";
const offersCTC = "/job-ctc-range";
const tat = "/tats";
const feedbackQualityOfferedCandidates = "/feedback-quality";
const tq = "/topic-coverage-distribution";
const hd = "/candidate-hirability-distribution";
const sf = "/sourced-from";
const sd = "/candidate-score-distribution";
const rr = "/rejection-reasons-stats";
const buy_ins = "/buyin-count";

export default {
  submissions(start_time: string, end_time: string) {
    return axiosInstance.get(
      `${base}${submissions}/?start_time=${start_time}&end_time=${end_time}`,
    );
  },
  submissionsPerJob(start_time: string, end_time: string) {
    return axiosInstance.get<TCandidateSubmittedPerJob>(
      `${base}${submissions_per_job}/?start_time=${start_time}&end_time=${end_time}`,
    );
  },
  offersCTC(start_time: string, end_time: string) {
    return axiosInstance.get(
      `${base}${offersCTC}/?start_time=${start_time}&end_time=${end_time}`,
    );
  },
  tat(
    start_time: string,
    end_time: string,
    compare1: string,
    compare2: string,
  ) {
    return axiosInstance.get(
      `${base}${tat}/?start_time=${start_time}&end_time=${end_time}&compare=${compare1}&compare=${compare2}`,
    );
  },
  feedbackQualityOfferedCandidates(
    start_time: string,
    end_time: string,
    candidate_status?: string,
  ) {
    let url = `${base}${feedbackQualityOfferedCandidates}/?start_time=${start_time}&end_time=${end_time}`;

    if (candidate_status !== undefined) {
      url += `&candidate_status=${candidate_status}`;
    } else {
      url += `&status=Offered`;
    }

    return axiosInstance.get<TFQResponse>(url);
  },
  topic_coverage(start_time: string, end_time: string, status?: string) {
    let url = `${base}${tq}/?start_time=${start_time}&end_time=${end_time}&mode=avg`;

    if (status !== undefined) {
      url += `&status=${status}`;
    } else {
      url += `&candidate_status=Qualified`;
    }

    return axiosInstance.get<{ data: number[] }>(url);
  },
  candidateHireabilityDistributionStatus(
    start_time: string,
    end_time: string,
    status: string,
  ) {
    return axiosInstance.get(
      `${base}${hd}/?start_time=${start_time}&end_time=${end_time}&status=${status}`,
    );
  },
  candidateHireabilityDistributionCandidateStatus(
    start_time: string,
    end_time: string,
    status: string,
  ) {
    return axiosInstance.get<THireabilityDistResponse>(
      `${base}${hd}/?start_time=${start_time}&end_time=${end_time}&candidate_status=${status}`,
    );
  },
  sourcedFromDistribution(start_time: string, end_time: string) {
    return axiosInstance.get<{
      [key: string]: number;
    }>(`${base}${sf}/?start_time=${start_time}&end_time=${end_time}`);
  },
  buyIns(start_time: string, end_time: string) {
    return axiosInstance.get<IBuyIns>(
      `${base}${buy_ins}/?start_time=${start_time}&end_time=${end_time}`,
    );
  },
  candidateScoreDistributionOC(start_time: string, end_time: string) {
    return axiosInstance.get<IScoreRangeCounts>(
      `${base}${sd}/?start_time=${start_time}&end_time=${end_time}&status=Offered`,
    );
  },
  candidateScoreDistributionQC(start_time: string, end_time: string) {
    return axiosInstance.get<IScoreRangeCounts>(
      `${base}${sd}/?start_time=${start_time}&end_time=${end_time}&candidate_status=Qualified`,
    );
  },
  rejectionReasons(start_time: string, end_time: string) {
    return axiosInstance.get<TStringNumberMap>(
      `${base}${rr}/?start_time=${start_time}&end_time=${end_time}&candidate_status=Qualified`,
    );
  },
};
